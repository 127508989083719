import { useAPI } from "@/services/api";
import { useEffect } from "react";
import { useSearchParams } from "@Light/utils/context";
import { useScaffold } from "@Light/scaffold";
import { setFlowAttribution } from "@Light/app/analytics";

export type LoginProps = {};

export const Login: React.FC<LoginProps> = ({}) => {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") ?? "";
  const attribution = searchParams.get("attribution") ?? "";
  const plan = searchParams.get("plan") ?? "";
  const { useLoginMutation } = useAPI();
  const [login, loginState] = useLoginMutation();
  const scaffold = useScaffold();

  useEffect(() => {
    if (!token) {
      return;
    }

    setFlowAttribution(attribution);
    login({ token });
  }, [login, token]);

  const Navigate = scaffold.system.navigate;
  if (!token || loginState.isError || loginState.isSuccess) {
    return <Navigate to={`/?${new URLSearchParams({ plan })}`} />;
  }

  return null;
};
